import Mixins from "../../Mixins.js";

export default {
  name: "EditSaving",
  mixins: [Mixins],
  data() {
    return {
      identity: {
        savingId: "",
      },
      property: {
        animation: {
          editSaving: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        zakatChartOfAccountId: "",
        pajakChartOfAccountId: "",
        profitShareChartOfAccountId: "",
        bonusChartOfAccountId: "",
        savingCode: "",
        savingName: "",
        currencyId: "",
        profitSharePercent: "",
        balanceMinimum: "",
        initialDepositMinimum: "",
        nextDepositMinimum: "",
        perdayWithdrawMaximum: "",
        depositMultiple: "",
        passiveDays: "",
        isTransactionSlipAutomatic: false,
        isBlockAccount: false,
        nominalNotTaxable: "",
        tax: "",
        zakat: "",
        costAdminMonthly: "",
        costAccountClose: "",
        costBookChange: "",
        costCurrentAccountPrint: "",
        costAccountPassive: "",
        costBalanceMinimum: "",
        daysNotPrinted: "",
        isProfitAdminSame: false,
        isFreeAdmin: false,
        balanceMinimumFreeAdmin: "",
        isCloseBalanceMinimum: "",
        zakatAccountNumber: "",
        infaqAccountNumber: "",
        accountStatus: "",
        nisbahSpecial: "",
        savingAkadTypeId: "",
        savingAppreciateTypeId: "",
        savingBaseCalculateId: "",
        savingMediaPrintId: "",
        savingTypeId: "",
        savingProfitShareAllocationId: "",
        savingNisbahTypeId: "",
        statusId: "",
        productCategoryName: "",
        chartOfAccountId: "",
      },
      options: {
        jenisProduk: [],
        jenisApresiasi: [],
        dasarPerhitungan: [],
        mediaCetakTransaksi: [],
        jenisAkadTabungan: [],
        jenisTabungan: [],
        alokasiBagiHasil: [],
        mataUang: [],
        parameterCoaList: [],
      },
    };
  },
  methods: {
    routeToPageListSaving() {
      this.$router.push("list");
    },
    async editSaving() {
      const payload = {
        savingCode: this.dataForm.savingCode,
        savingName: this.dataForm.savingName,
        currencyId: this.dataForm.currencyId,
        profitSharePercent: this.dataForm.profitSharePercent,
        balanceMinimum: this.dataForm.balanceMinimum,
        initialDepositMinimum: this.dataForm.initialDepositMinimum,
        nextDepositMinimum: this.dataForm.nextDepositMinimum,
        perdayWithdrawMaximum: this.dataForm.perdayWithdrawMaximum,
        depositMultiple: this.dataForm.depositMultiple,
        passiveDays: this.dataForm.passiveDays,
        isTransactionSlipAutomatic: this.dataForm.isTransactionSlipAutomatic,
        isBlockAccount: this.dataForm.isBlockAccount,
        nominalNotTaxable: this.dataForm.nominalNotTaxable,
        tax: this.dataForm.tax,
        zakat: this.dataForm.zakat,
        costAdminMonthly: this.dataForm.costAdminMonthly,
        costAccountClose: this.dataForm.costAccountClose,
        costBookChange: this.dataForm.costBookChange,
        costCurrentAccountPrint: this.dataForm.costCurrentAccountPrint,
        costAccountPassive: this.dataForm.costAccountPassive,
        costBalanceMinimum: this.dataForm.costBalanceMinimum,
        daysNotPrinted: this.dataForm.daysNotPrinted,
        isProfitAdminSame: this.dataForm.isProfitAdminSame,
        isFreeAdmin: this.dataForm.isFreeAdmin,
        balanceMinimumFreeAdmin: this.dataForm.balanceMinimumFreeAdmin,
        isCloseBalanceMinimum: this.dataForm.isCloseBalanceMinimum,
        zakatAccountNumber: this.dataForm.zakatAccountNumber,
        infaqAccountNumber: this.dataForm.infaqAccountNumber,
        accountStatus: this.dataForm.accountStatus,
        nisbahSpecial: this.dataForm.nisbahSpecial,
        savingAkadTypeId: this.dataForm.savingAkadTypeId,
        savingAppreciateTypeId: this.dataForm.savingAppreciateTypeId,
        savingBaseCalculateId: this.dataForm.savingBaseCalculateId,
        savingMediaPrintId: this.dataForm.savingMediaPrintId,
        savingTypeId: this.dataForm.savingTypeId,
        savingProfitShareAllocationId: this.dataForm
          .savingProfitShareAllocationId,
        savingNisbahTypeId: this.dataForm.savingNisbahTypeId,
        statusId: this.dataForm.statusId,
        productCategoryId: this.dataForm.productCategoryId,
        chartOfAccountId: this.dataForm.chartOfAccountId,
        zakatChartOfAccountId: this.dataForm.zakatChartOfAccountId,
        pajakChartOfAccountId: this.dataForm.pajakChartOfAccountId,
        profitShareChartOfAccountId: this.dataForm.profitShareChartOfAccountId,
        bonusChartOfAccountId: this.dataForm.bonusChartOfAccountId,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.editSaving.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "transaction",
                reqUrl: "saving/" + this.identity.savingId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.editSaving.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async getSavingById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "saving/" + this.identity.savingId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.savingCode = resp.data.data.savingCode;
            this.dataForm.savingName = resp.data.data.savingName;
            this.dataForm.currencyId = resp.data.data.currencyId;
            this.dataForm.profitSharePercent =
              resp.data.data.profitSharePercent;
            this.dataForm.balanceMinimum = resp.data.data.balanceMinimum;
            this.dataForm.initialDepositMinimum =
              resp.data.data.initialDepositMinimum;
            this.dataForm.nextDepositMinimum =
              resp.data.data.nextDepositMinimum;
            this.dataForm.perdayWithdrawMaximum =
              resp.data.data.perdayWithdrawMaximum;
            this.dataForm.depositMultiple = resp.data.data.depositMultiple;
            this.dataForm.passiveDays = resp.data.data.passiveDays;
            this.dataForm.isTransactionSlipAutomatic =
              resp.data.data.isTransactionSlipAutomatic;
            this.dataForm.isBlockAccount = resp.data.data.isBlockAccount;
            this.dataForm.nominalNotTaxable = resp.data.data.nominalNotTaxable;
            this.dataForm.tax = resp.data.data.tax;
            this.dataForm.zakat = resp.data.data.zakat;
            this.dataForm.costAdminMonthly = resp.data.data.costAdminMonthly;
            this.dataForm.costAccountClose = resp.data.data.costAccountClose;
            this.dataForm.costBookChange = resp.data.data.costBookChange;
            this.dataForm.costCurrentAccountPrint =
              resp.data.data.costCurrentAccountPrint;
            this.dataForm.costAccountPassive =
              resp.data.data.costAccountPassive;
            this.dataForm.costBalanceMinimum =
              resp.data.data.costBalanceMinimum;
            this.dataForm.daysNotPrinted = resp.data.data.daysNotPrinted;
            this.dataForm.isProfitAdminSame = resp.data.data.isProfitAdminSame;
            this.dataForm.isFreeAdmin = resp.data.data.isFreeAdmin;
            this.dataForm.balanceMinimumFreeAdmin =
              resp.data.data.balanceMinimumFreeAdmin;
            this.dataForm.isCloseBalanceMinimum =
              resp.data.data.isCloseBalanceMinimum;
            this.dataForm.zakatAccountNumber =
              resp.data.data.zakatAccountNumber;
            this.dataForm.infaqAccountNumber =
              resp.data.data.infaqAccountNumber;
            this.dataForm.accountStatus = resp.data.data.accountStatus;
            this.dataForm.nisbahSpecial = resp.data.data.nisbahSpecial;
            this.dataForm.savingAkadTypeId = resp.data.data.rsavingAkadType
              ? resp.data.data.rsavingAkadType.akadTypeId
              : "";
            this.dataForm.savingTypeId = resp.data.data.rsavingType
              ? resp.data.data.rsavingType.savingTypeId
              : "";
            this.dataForm.savingAppreciateTypeId = resp.data.data
              .rsavingAppreciateType
              ? resp.data.data.rsavingAppreciateType.appreciateTypeId
              : "";
            this.dataForm.savingBaseCalculateId = resp.data.data
              .rsavingBaseCalculate
              ? resp.data.data.rsavingBaseCalculate.baseCalculateId
              : "";
            this.dataForm.savingProfitShareAllocationId = resp.data.data
              .rsavingProfitShareAllocation
              ? resp.data.data.rsavingProfitShareAllocation
                  .profitShareAllocationId
              : "";
            this.dataForm.savingNisbahTypeId = resp.data.data.rsavingNisbahType
              ? resp.data.data.rsavingNisbahType.nisbahTypeId
              : "";
            this.dataForm.statusId = resp.data.data.rstatus
              ? resp.data.data.rstatus.statusId
              : "";
            this.dataForm.savingMediaPrintId = resp.data.data.rsavingMediaPrint
              ? resp.data.data.rsavingMediaPrint.mediaPrintId
              : "";
            this.dataForm.productCategoryId = resp.data.data.mproductCategory
              ? resp.data.data.mproductCategory.productCategoryId
              : "";
            this.dataForm.chartOfAccountId = resp.data.data.mchartOfAccountDto
              ? resp.data.data.mchartOfAccountDto.chartOfAccountId
              : "";
            this.dataForm.zakatChartOfAccountId =
              resp.data.data.zakatChartOfAccountId;
            this.dataForm.pajakChartOfAccountId =
              resp.data.data.pajakChartOfAccountId;
            this.dataForm.profitShareChartOfAccountId =
              resp.data.data.profitShareChartOfAccountId;
            this.dataForm.bonusChartOfAccountId =
              resp.data.data.bonusChartOfAccountId;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListSaving(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : `Terjadi Kesalahan `,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListSaving(),
          });
        }
      }
    },
    async getReferenceSavingAppreciate() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-appreciate-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.appreciateTypeId;
            const text = i.appreciateTypeName;
            this.options.jenisApresiasi.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceParameterProductCategory() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_PRODUCT_CATEGORY"
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.productCategoryId;
            const text = i.productCategoryName;
            this.options.jenisProduk.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceSavingMediaPrint() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-media-print",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const text = i.mediaPrintName;
            const value = i.mediaPrintId;
            this.options.mediaCetakTransaksi.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceSavingAkadType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.akadTypeId;
            const text = i.akadTypeName;
            this.options.jenisAkadTabungan.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceSavingBaseCalculate() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-base-calculate",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.baseCalculateId;
            const text = i.baseCalculateName;
            this.options.dasarPerhitungan.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceSavingType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.savingTypeId;
            const text = i.savingTypeName;
            this.options.jenisTabungan.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceSavingProfitShareAllocation() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "saving-profit-share-allocation",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.profitShareAllocationId;
            const text = i.profitShareAllocationName;
            this.options.alokasiBagiHasil.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceCurrency() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              currencyName: "",
              page: 0,
            },
            url: "m-currency",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = i.currencyName;
            const value = i.currencyId;
            this.options.mataUang.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceParameterChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "chart-of-account",
            params: {
              description: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = `${index.chartOfAccountCode} - ${index.description}`;
            const value = index.chartOfAccountId;
            this.options.parameterCoaList.push({ text, value });
          });
        }
      } catch (error) {}
    },
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem("SAVING_ID_EDIT");
      this.identity.savingId = getTokenFromSession;
      this.$store.dispatch("SET_IDENTIFIER_SAVING_ID", getTokenFromSession);
    },
  },
  mounted() {
    this.getIdentityFromSession();
    this.getSavingById();
    this.getReferenceSavingAppreciate();
    this.getReferenceParameterProductCategory();
    this.getReferenceSavingMediaPrint();
    this.getReferenceSavingAkadType();
    this.getReferenceSavingBaseCalculate();
    this.getReferenceSavingType();
    this.getReferenceSavingProfitShareAllocation();
    this.getReferenceCurrency();
    this.getReferenceParameterChartOfAccount();
  },
};
